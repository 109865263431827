import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import CalendarMonthIcon from "@mui/icons-material/AddBusiness";
import AppsIcon from "@mui/icons-material/Apps";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import Groups2Icon from "@mui/icons-material/Groups2";
import PollIcon from "@mui/icons-material/Poll";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ReviewsIcon from "@mui/icons-material/Reviews";
import SettingsIcon from "@mui/icons-material/Settings";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { GoGoal } from "react-icons/go";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { getPmsOrgSettings } from "../../modules/Configuration/apiPromises";
import { selectActiveListItem } from "../../redux/rootAction";
import { NestedMenu } from "./CustomMenus";

const mappingForMenuIcons = {
  apps: <AppsIcon />,
  Dashboard: <DashboardIcon />,
  People: <BusinessIcon />,
  Performance: <GroupIcon />,
  Feedback: <CalendarMonthIcon />,
  "One to One": <PunchClockIcon />,
  Compensation: <RequestPageIcon />,
  Reports: <PollIcon />,
  Configuration: <SettingsIcon />,
  "My Team": <Groups2Icon />,
  Objective: <GoGoal style={{ width: "1.325rem", height: "1.325rem" }} />,
  Review: <ReviewsIcon />,
  Settings: <SettingsIcon />,
  Incentive: <FaMoneyCheckAlt style={{ width: "1.325rem", height: "1.325rem" }} />,
  "Admin Dashboard": <DashboardIcon />,
};

const LeftNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [secondaryColor, setSecondaryColor] = useState("");
  const { activeListItem } = useSelector(
    (state) => state.adminLocalStateReducer,
    isEqual
  );
  const intl = useIntl();
  const {
    menu_access = [],
    menu_access_tree = [],
    user = {},
    token,
  } = useSelector((state) => state.adminUserReducer, isEqual);
  const popoverAnchor = useRef(null);
  const [dynamicDrawerList, setDynamicDrawerList] = useState([]);
  const [lightBackground, setLightBackground] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [nestedArr, setNestedArr] = useState([]);

  const location = useLocation();

  //   useEffect(()=>{
  // console.log({activeListItem})
  //   } , [activeListItem])

  const menu_access_mapping = useMemo(() => {
    return menu_access?.length ? groupBy(menu_access, "name") : {};
  }, [menu_access]);

  const menu_access_mapping_parent_id = useMemo(() => {
    return menu_access?.length ? groupBy(menu_access, "parent_id") : {};
  }, [menu_access]);

  const { data: allSettingData, isFetching: isLoadingSettingData } = useFetch(
    getPmsOrgSettings,
    {
      token,
      org_id: user.org_id,
    },
    "org_setting",
    {
      cacheTime: 500 * 60 * 1000,
      staleTime: 500 * 60 * 1000,
      onSuccess: () => { },
      onError: () => { },
      enabled: Boolean(user?.org_id),
    }
  );

  const hideTeam = useMemo(
    () => allSettingData?.TEAM_VISIBILITY === 1,
    [allSettingData]
  );

  useEffect(() => {
    dispatch(
      selectActiveListItem(location.pathname.split(/[?#]/)[0].split("/")[1])
    );
  }, [location, dispatch]);

  useEffect(() => {
    const { PRODUCT_THEME_COLOR: ptc } = user;
    if (
      ptc === "#E5B099" ||
      ptc === "#E0BCBE" ||
      ptc === "#E4D1C2" ||
      ptc === "#FFFFFF" ||
      ptc === null ||
      ptc?.length === 0
    ) {
      setLightBackground(true);
    }

    if (ptc === "#3B5352") {
      setSecondaryColor("#445F5E");
    } else if (ptc === "#172B4D") {
      setSecondaryColor("#023767");
    } else if (ptc === "#E0BCBE") {
      setSecondaryColor("#D2A8AD");
    } else if (ptc === "#E4D1C2") {
      setSecondaryColor("#D7BEAE");
    } else if (ptc === "#34004B") {
      setSecondaryColor("#52176B");
    } else if (ptc === "#5B1B39") {
      setSecondaryColor("#6F2145");
    } else if (ptc === "#6F2145") {
      setSecondaryColor("#D2A8AD");
    } else if (ptc === "#E5B099") {
      setSecondaryColor("#D6997F");
    } else if (ptc === "#893A55") {
      setSecondaryColor("#792F49");
    } else if (ptc === "#FFFFFF" || ptc === null || ptc?.length === 0) {
      // setSecondaryColor("#335AFF");
      setSecondaryColor("#ffffff");
    }
  }, [user]);

  const { PRODUCT_THEME_COLOR: ptc } = user;

  useEffect(() => {
    let dynamicList = [];
    if (menu_access?.length && menu_access_tree?.length) {
      menu_access.forEach((element) => {
        if (element.parent_id === 0) {
          let child = menu_access_tree.filter(
            (item) => item.name == element?.name
          );

          if (child.length && child[0].children.length) {
            // console.log('children' , child, child[0].children[0].url)
            let url = "";
            if (child[0].children[0].children.length) {
              url = child[0].children[0].children[0].url;
            } else {
              url = child[0].children[0].url;
            }
            dynamicList.push({
              icon: element.icon_class,
              name: element.name,
              title: element.name,
              isAdmin: true,
              navigate: url,
              element,
            });
          } else {
            dynamicList.push({
              icon: element.icon_class,
              name: element.name,
              title: element.name,
              isAdmin: true,
              navigate: element.url,
              element,
            });
          }
        }
      });

      setDynamicDrawerList(dynamicList);
    }
  }, [menu_access, menu_access_tree]);

  const popoverEnter = ({ currentTarget }) => {
    setOpen(true);
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpen(false);
  };

  return (
    <Drawer
      anchor="left"
      className="drawerArea left-navigate"
      variant="permanent"
      open={true}
      PaperProps={{
        style: {
          width: "inherit",
          background: user.PRODUCT_THEME_COLOR || "#172B4D",
          backgroundImage: `linear-gradient(to right,${user.PRODUCT_THEME_COLOR} 60% , white 40%)`,
          border: "none",
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className="navigationArea start-flex flex-column wh-100"
        style={{
          height: "100%",
          background: user.PRODUCT_THEME_COLOR || "#172B4D",
          color: !lightBackground ? "rgba(255, 255, 255, 0.5)" : "",
          border: "none",
        }}
      >
        <Box className="wrapper-class" sx={{ height: "100vh", width: "100%" }}>
          <Box
            className="navigationArea start-flex flex-column wh-100"
            style={{
              height: "90%",
              background: ptc,
              color: !lightBackground ? "rgba(255, 255, 255, 0.5)" : "",
              // border: "none",
              marginTop: 90,
              overflowY: "auto",
            }}
          >
            <Box className="listing" sx={{ width: "100%" }}>
              <List className="mp-0 wh-100 overflow-overlay">
                {dynamicDrawerList
                  .filter(
                    (item) =>
                      Boolean(menu_access_mapping[item.name]?.[0]) &&
                      (item?.element?.menu_layout === "MY_TEAM"
                        ? !hideTeam
                        : true)
                  )
                  .map((item, index) => (
                    <Box key={index}>
                      <ListItem
                        ref={popoverAnchor}
                        className={`mp-0 navigationList flex-start-center`}
                        onMouseEnter={(e) => {
                          let selectedItem = menu_access_mapping[
                            item.name
                          ].filter((elem) => elem.parent_id === 0)?.[0];

                          if (selectedItem) {
                            // console.log({selectedItem})
                            let nestedArr1 = menu_access?.filter(
                              (val) => val.parent_id === selectedItem?.id
                            );

                            if (nestedArr1?.length) {
                              setNestedArr(nestedArr1);
                            } else {
                              setNestedArr([]);
                            }
                            popoverEnter(e);
                            setAnchorEl(e?.currentTarget);
                          }
                        }}
                        onMouseLeave={(e) => {
                          popoverLeave(e);
                        }}
                        onClick={(e) => {
                          popoverLeave(e);
                          setTimeout(() => {
                            let selectedItem =
                              menu_access_mapping[item.name]?.[0];
                            if (selectedItem) {
                              e.preventDefault();
                              navigate(`/${item.navigate}`);
                            }
                          }, 10);
                        }}
                        sx={
                          activeListItem === item?.navigate ||
                            activeListItem === item?.name?.toLocaleLowerCase()
                            ? {
                              cursor: "default",
                              backgroundColor: secondaryColor,
                              borderRadius: "3%",
                              color: !lightBackground
                                ? "#FFF"
                                : "rgba(35, 31, 32, 1)",
                              "&:hover": {
                                backgroundColor: secondaryColor,
                                borderRadius: "3%",
                                color: lightBackground ? "black" : "white",
                              },
                            }
                            : {
                              cursor: "pointer",
                              backgroundColor: "transparent",
                              "&:hover": {
                                backgroundColor: secondaryColor,
                                borderRadius: "3%",
                                color: !lightBackground
                                  ? "white "
                                  : "rgba(35, 31, 32,1)",
                              },
                            }
                        }
                      >
                        <ListItemText
                          className="listitemtext"
                          style={{
                            paddingTop: 2,
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                          primary={
                            <Typography
                              className="list-text-color2"
                              sx={{
                                color: lightBackground
                                  ? activeListItem === item.navigate ||
                                    activeListItem ===
                                    item?.name?.toLocaleLowerCase()
                                    ? ptc === "#FFFFFF"
                                      ? "#335AFF"
                                      : "rgba(35, 31, 32, 1)"
                                    : "rgba(35, 31, 32, 0.5)"
                                  : activeListItem === item.navigate ||
                                    activeListItem ===
                                    item?.name?.toLocaleLowerCase()
                                    ? ptc === "#FFFFFF"
                                      ? "#335AFF"
                                      : "rgba(255, 255, 255, 1)"
                                    : "rgba(259, 255, 255, 0.6)",
                                "&:hover": {
                                  color: !lightBackground
                                    ? "#FFFFFF"
                                    : ptc === "#FFFFFF"
                                      ? "#335AFF"
                                      : "#231F20",
                                },
                              }}
                            >
                              <Box
                                className="listIcon"
                                style={{ paddingTop: 2 }}
                              >
                                {mappingForMenuIcons[item?.name] ? (
                                  mappingForMenuIcons[item?.name]
                                ) : (
                                  <GroupIcon />
                                )}
                              </Box>
                              <Typography
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                  color: "currentColor",
                                  textTransform: "capitalize",
                                }}
                              >
                                {intl.formatMessage({ id: `navbar.${item?.title}`, defaultMessage: item?.title })}
                                {/* {item?.title} */}
                              </Typography>
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Box>
                  ))}
              </List>
            </Box>
          </Box>
        </Box>

        {open && Array.isArray(nestedArr) && nestedArr.length > 0 && (
          <div
            style={
              open && nestedArr.length > 0
                ? {}
                : {
                  display: "none !important",
                }
            }
          >
            <NestedMenu
              open={open}
              setOpen={setOpen}
              anchorEl={anchorEl}
              nestedArr={nestedArr}
              lightBackground={lightBackground}
              secondaryColor={secondaryColor}
              popoverAnchor={popoverAnchor}
              popoverEnter={popoverEnter}
              popoverLeave={popoverLeave}
              menu_access_mapping_parent_id={menu_access_mapping_parent_id}
            />
          </div>
        )}
      </Box>
    </Drawer>
  );
};
export default LeftNavigation;
