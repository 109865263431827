import axios from "axios";
import apiPath from "../../config/apiPath.json";
const BASE_URL = process.env.REACT_APP_SERVER_URL;
const HRMS_BASE_URL = process.env.REACT_APP_HRMS_API_URL;

export const addUpdatemethodlogies = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.ADD_METOHODOLOGIES}`, data, {
    headers: { token },
  });
};

export const addUpdateCycle = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.CREATE_CYCLE}`, data, {
    headers: { token },
  });
};

export const getPmsOrgSettings = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_PMS_ORG_SETTINGS}?org_id=${org_id}`,
    {
      headers: { token },
    },
    {}
  );
};

export const getCycleList = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_CYCLE_LIST}?org_id=${org_id}`,
    {
      headers: { token },
    },
    {}
  );
};

export const getCycleDetails = async ({ token, cycle_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_CYCLE_DETAILS}/${cycle_id}`,
    {
      headers: { token },
    },
    {}
  );
};

export const getAppraisalCycle = async ({
  token = "",
  org_id = "",
  cycle_type = "",
  search = "",
  start = "",
  limit = "",
}) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_APPRAISAL_CYCLE}?org_id=${org_id}&cycle_type=${cycle_type}&search=${search}&start=${start}&limit=${limit}`,
    {
      headers: { token },
    },
    {}
  );
};

export const getWorkflowDetails = async ({ token, cycle_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_WORKFLOW_DETAILS}/${cycle_id}`,
    {
      headers: { token },
    },
    {}
  );
};

export const addUpdateWorkflowMethodology = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.ADD_UPDATE_WORKFLOW_METOHODOLOGY}`,
    data,
    { headers: { token } }
  );
};

export const createAppraisal = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.CREATE_APPRAISAL_CYCLE}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};

export const updateAppraisalCycleStatus = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPDATE_APPRAISAL_CYCLE_STATUS}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};

export const updatePerformanceYearlySetting = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPDATE_PERFORMANCE_YEARLY_SETTING}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};

export const updateWattageConfiguration = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPDATE_WATTAGE_CONFIGURATION}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};

/**
 * Create objective cycle
 * @param {token,org_id} param0
 * @returns
 */
export const createObjectiveCycle = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.CREATE_REVIEW_CYCLE}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};
/**
 * Update cycle configuration
 * @param {token,org_id} param0
 * @returns
 */
export const updateCycleConfiguration = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPDATE_CYCLE_CONFIGURATION}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};
/**
 * Delete objective cycle
 * @param {token,org_id} param0
 * @returns
 */
export const deleteObjectiveCycle = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.DELETE_OBJECT_CYCLE}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};

/**
 * Get Org Settings Data
 * @param {token,org_id}} param0
 * @returns
 */
export const getOrgSettings = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.ORG_SETTINGS}?org_id=${org_id}`,
    {
      headers: { token },
    },
    {}
  );
};

/**
 * Get Org Settings Data
 * @param {token,org_id}} param0
 * @returns
 */
export const getAppraisalCycleList = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.CYCLE_LIST}`,
    {
      headers: { token },
    },
    {}
  );
};

/**
 * Get rating scale
 * @param {token,org_id}} param0
 * @returns
 */
export const getRatingScales = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.RATING_SCALES}?org_id=${org_id}`,
    {
      headers: { token },
    },
    {}
  );
};

/**
 * Post rating scale
 * @param {token,org_id}} param0
 * @returns
 */
export const createRatingScale = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.CREATE_RATING_SCALE}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};

export const createRatingScalePerformer = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.CREATE_RATING_SCALE_PERFORMER}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};

export const createRatingScaleAchievement = async ({ token, payload }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.CREATE_RATING_SCALE_ACHIEVEMENT}`,
    payload,
    {
      headers: { token },
    },
    {}
  );
};

export const getRatingScalesPerformer = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_RATING_SCALE_PERFORMER}?org_id=${org_id}`,
    {
      headers: { token },
    },
    {}
  );
};

export const getRatingScaleAchievement = async ({ token, org_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_RATING_SCALE_ACHIEVEMENT}?org_id=${org_id}`,
    {
      headers: { token },
    },
    {}
  );
};

/**
 * weightage configuration apis
 */
export const updateWeightageConfiguration = async ({
  token,
  WATTAGE_CONFIGURATION,
  KRA_KPI_VALIDATE,
  NUMBER_KRA,
  NUMBER_KPI,
  ...rest
}) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPDATE_WEIGHTAGE_CONFIGURATION}`,
    {
      NUMBER_KRA,
      WATTAGE_CONFIGURATION,
      KRA_KPI_VALIDATE,
      NUMBER_KPI,
      ...rest,
    },
    {
      headers: { token },
    }
  );
};

export const createAppraisalCycle = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.CREATE_APPRAISAL_CYCLE_STEPPER}`,
    data,
    {
      headers: { token },
    }
  );
};

export const editStructure = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.EDIT_STRUCTURE}`, data, {
    headers: { token },
  });
};

export const employeeCycle = async ({ token, employee_id, cycle_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.EMPLOYEE_CYCLE}?emp_id=${employee_id}&cycle_id=${cycle_id}`,
    {
      headers: { token },
    },
    {}
  );
};

export const updateEmployeeTenure = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.GET_UPDATE_EMPLOYEE_TENURE}`,
    data,
    {
      headers: { token },
    }
  );
};

export const updateApprovalMatrix = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.GET_UPDATE_APPROVAL_MATRIX}`,
    data,
    {
      headers: { token },
    }
  );
};

export const removeEmployeeMatrix = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.GET_REMOVE_EMPLOYEE_MATRIX}`,
    data,
    {
      headers: { token },
    }
  );
};

export const getKraSectionList = async ({ token, params }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_KRA_SECTION_LIST}`,
    {
      headers: { token },
      params,
    },
    {}
  );
};

export const getTeamProcessData = async ({ token, params }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.TEAM_PROCESS_DATA}`,
    {
      headers: { token },
      params
    },
    {}
  );
};

export const downloadObjectiveSample = async ({ token, applicable_to }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.DOWNLOAD_OBJECTIVE_SAMPLE}?applicable_to=${applicable_to}`,
    {
      headers: { token },
    },
    {}
  );
};

export const downloadObjectiveKeyResultSample = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.DOWNLOAD_OBJECTIVE_KEY_RESULT_SAMPLE}`,
    {
      headers: { token },
    },
    {}
  );
};

export const importObjective = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.IMPORT_OBJECTIVE}`, data, {
    headers: { token },
  });
};

export const importObjectiveKeyResult = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.IMPORT_OBJECTIVE_KEY_RESULT}`,
    data,
    {
      headers: { token },
    }
  );
};
export const employeeImportedTargetAchievement = async ({
  token,
  cycle_id,
  str_id,
  employee_id,
  cycle_quarter,
}) => {
  return await axios.get(
    `${BASE_URL}${apiPath.EMPLOYEE_IMPORTED_TARGETS_ACHIEVEMENT}?cycle_quarter=${cycle_quarter}&employee_id=${employee_id}&str_id=${str_id}&cycle_id=${cycle_id}`,
    {
      headers: { token },
    },
    {}
  );
};

export const keyResultLogs = async ({ token, limit, start }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.BULK_KEY_RESULT_LOGS}?limit=${limit}&start=${start}`,
    { headers: { token } },
    {}
  );
};

export const objectiveLogs = async ({ token, limit, page, type }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.OBJECTIVE_LOGS}?limit=${limit}&page=${page}&type=${type}`,
    { headers: { token } },
    {}
  );
};

export const uploadKraBulk = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath?.BULK_UPLOAD__SECTION}`, data, {
    headers: { token },
  });
};

export const uploadKpiBulk = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath?.BULK_ATTRIBUTE}`, data, {
    headers: { token },
  });
};

export const potentialBulkSection = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.POTENTIAL_BULK_SECTION}`,
    data,
    { headers: { token } }
  );
};

export const competencyBulkSection = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.BULK_COMPETENCY_SECTION}`,
    data,
    { headers: { token } }
  );
};

export const createBulkStructure = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.BULK_STRUCTURE_CREATE}`, data, {
    headers: { token },
  });
};

export const keyResultProgress = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_KEY_RESULT_PROGRESS}`,
    { headers: { token } },
    {}
  );
};

export const objectiveCycledetails = async ({ token, CID }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_OBJECTIVE_CYCLE_DETAILS}?CID=${CID}`,
    { headers: { token } },
    {}
  );
};

export const deleteObjectives = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.DELETE_OBJECTIVE}`, data, {
    headers: { token },
  });
};

export const deleteObjectivesKeyResult = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.DELETE_OBJECTIVE_KEY_RESULT}`,
    data,
    {
      headers: { token },
    }
  );
};

export const createObjective = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.CREATE_OBJECTIVE}`, data, {
    headers: { token },
  });
};

export const editObjective = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.UPDATE_OBJECTIVE}`, data, {
    headers: { token },
  });
};

export const getMasterCategories = async ({ token }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_MASTER_CATEGORIES}`,
    { headers: { token } },
    {}
  );
};

export const getMasterDataByCategories = async ({ token, category_id }) => {
  return await axios.get(
    `${BASE_URL}${apiPath.GET_MASTER_DATA_BY_CATEGORIES}?category_id=${category_id}`,
    { headers: { token } },
    {}
  );
};

export const cloneStructureApi = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.CLONE_STRUCTURE}`, data, {
    headers: { token },
  });
};

export const createStructureApi = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.CREATE_STRUCTURE}`, data, {
    headers: { token },
  });
};

export const bulkUploadGoals = async ({ token, data }) => {
  return await axios.post(`${BASE_URL}${apiPath.BULK_UPLOAD_GOALS}`, data, {
    headers: { token },
  });
};

export const bulkUploadGoalsAchievement = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.BULK_UPLOAD_GOALS_ACHIEVEMENT}`,
    data,
    {
      headers: { token },
    }
  );
};

export const bulkUploadReviewApproval = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPLOAD_CYCLE_APPROVAL}`,
    data,
    {
      headers: { token },
    }
  );
};


export const bulkUploadQuickPerformanceEvalaution = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPLOAD_QUICK_PERFORMANCE_EVALUATION}`,
    data,
    {
      headers: { token },
    }
  );
};

export const bulkUploadNormalizationGrade = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPLOAD_NORMALIZATION_GRADE_UPDATE}`,
    data,
    {
      headers: { token },
    }
  );
};

export const bulkUploadObjectiveData = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPLOAD_OKR_DATA_SAMPLE}`,
    data,
    {
      headers: { token },
    }
  );
};

export const bulkUploadKeyResultDelete = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPLOAD_KEY_RESULT_DELETE}`,
    data,
    {
      headers: { token },
    }
  );
};


export const bulkUploadMasterKeyResult = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPLOAD_KEY_RESULT}`,
    data,
    {
      headers: { token },
    }
  );
};

export const bulkUploadMoveToPreviousStage = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPLOAD_MOVE_TO_PREVIOUS_STAGE_SAMPLE}`,
    data,
    {
      headers: { token },
    }
  );
};


export const bulkUploadIncentiveCategory = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.BULK_UPLOAD_DOWNLOAD_INCENTIVE_CATEGORY}`,
    data,
    {
      headers: { token },
    }
  );
};

export const bulkUploadIncentiveSlabs = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.BULK_UPLOAD_DOWNLOAD_INCENTIVE_SLAB}`,
    data,
    {
      headers: { token },
    }
  );
};


export const bulkUploadObjectiveCheckin = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.UPLOAD_BULK_CHECKIN}`,
    data,
    {
      headers: { token },
    }
  );
};

export const bulkUploadInitiatePip = async ({ token, data }) => {
  return await axios.post(
    `${HRMS_BASE_URL}${apiPath.UPLOAD_INITIATE_PIP}`,
    data,
    {
      headers: { token },
    }
  );
};

export const bulkUploadCalculateEmpIncentive = async ({ token, data }) => {
  return await axios.post(
    `${BASE_URL}${apiPath.CALCULATE_EMP_INCENTIVE}`,
    data,
    {
      headers: { token },
    }
  );
};